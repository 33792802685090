import { createContext, useContext, useEffect, useState } from "react";
import {
  LoginErrorInterface,
  loginInterface,
  metaLoginInterface,
} from "../interfaces";
import { api } from "../utils/api";
import { AxiosResponse } from "axios";

export interface AuthContextType {
  user: metaLoginInterface | undefined;
  setUser: any;
  loginWithMeta: (isInstagramLogin: boolean) => void;
  loginWithEmailAndPassword: (
    user: loginInterface
  ) => Promise<metaLoginInterface | LoginErrorInterface>;
  checkLoginState: () => void;
  isLoading: boolean;
  hasInstagram: boolean;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<metaLoginInterface | undefined>(undefined);
  const [isSdkLoaded, setIsSdkLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasInstagram, setHasInstagram] = useState<boolean>(false);
  const loginWithEmailAndPassword = async (
    user: loginInterface
  ): Promise<metaLoginInterface | LoginErrorInterface> => {
    try {
      setIsLoading(true);
      const response = await api.post("auth/cyt-login", user);
      setIsLoading(false);
      if (response.status === 200) {
        let userResponse: metaLoginInterface =
          response.data as metaLoginInterface;
        userResponse.statusCode = 200;
        return userResponse;
      } else {
        return response.data;
      }
    } catch (error) {
      setIsLoading(false);
      let errorResponse: LoginErrorInterface = {
        message: "Error al iniciar sesión",
        error: "Error al iniciar sesión",
        statusCode: 400,
      };
      return errorResponse;
    }
  };
  const checkLoginState = () => {
    setIsLoading(true);
    window.FB.getLoginStatus(function (response) {
      if (response.status === "connected") {
        const { accessToken } = response.authResponse;
        window.FB.api("/me", {}, (profile: any) => {
          if (profile) {
            facebookLogin({
              token: accessToken,
              name: profile.name,
              email: profile.email ?? "-",
              providerId: profile.id,
            });
          } else {
            console.error("No se pudo obtener la información del perfil.");
          }
        });
      } else {
        console.log("disconected");
      }
      setIsLoading(false);
    });
  };
  const loginWithMeta = (isInstagramLogin: boolean) => {
    setIsLoading(true);
    setHasInstagram(isInstagramLogin);
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const { accessToken } = response.authResponse;
          window.FB.api("/me", {}, (profile: any) => {
            if (profile) {
              facebookLogin({
                token: accessToken,
                name: profile.name,
                email: profile.email ?? "-",
                providerId: profile.id,
              });
            } else {
              console.error("No se pudo obtener la información del perfil.");
            }
          });
        } else {
          console.log("Inicio de sesión cancelado por el usuario.");
        }
        setIsLoading(false);
      },
      {
        scope: `pages_read_user_content,pages_manage_engagement,email,pages_read_engagement, pages_manage_posts,pages_messaging,business_management,pages_show_list,public_profile${
          isInstagramLogin ? ",instagram_basic,instagram_manage_messages" : ""
        }`,
      }
    );
  };
  const facebookLogin = (user: metaLoginInterface) => {
    setIsLoading(true);
    api
      .post("/auth/facebbok-login", user)
      .then((response: AxiosResponse<metaLoginInterface>) => {
        if (response.status === 200) {
          let metaUser: metaLoginInterface = response.data;
          metaUser.statusCode = response.status;
          metaUser.providerId = user.providerId;
          metaUser.token = user.token;
          setUser(metaUser);
          sessionStorage.setItem("user", JSON.stringify(metaUser));
          sessionStorage.setItem("token", metaUser.access_token!);
          setIsLoading(false);
        }
      })
      .catch((error: any) => {
        console.error(error);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    const configurationId =
      process.env.REACT_APP_META_APP_ID?.toString() ?? "514045830281007";
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: configurationId,
        autoLogAppEvents: true,
        cookie: true,
        xfbml: true,
        version: process.env.REACT_APP_META_API_VERSION?.toString() ?? "v21.0",
      });
      setIsSdkLoaded(true);
    };
    (function (d, s, id) {
      // Se declara `fjs` como `HTMLScriptElement` para tener acceso a `src`
      const fjs = d.getElementsByTagName(s)[0] as HTMLScriptElement;

      // Si el elemento con ID `id` ya existe, salimos de la función
      if (d.getElementById(id)) return;

      // Crear el nuevo script y asignar la propiedad `src`
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/es_LA/sdk.js";

      // Asegurarse de que `parentNode` no es `null`
      if (fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }
    })(document, "script", "facebook-jssdk");
  }, []);
  useEffect(() => {
    if (isSdkLoaded && window.FB) {
      window.FB.getLoginStatus(function (response: any) {
        if (response.status === "connected") {
          const { accessToken } = response.authResponse;
          window.FB.api("/me", {}, (profile: any) => {
            if (profile) {
              facebookLogin({
                token: accessToken,
                name: profile.name,
                email: profile.email ?? "-",
                providerId: profile.id,
              });
            } else {
              console.error("No se pudo obtener la información del perfil.");
            }
          });
        } else {
          console.log("disconected");
        }
      });
    }
  }, [isSdkLoaded]);
  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        loginWithMeta,
        checkLoginState,
        isLoading,
        loginWithEmailAndPassword,
        hasInstagram,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext debe usarse dentro de un AuthProvider");
  }

  return context;
};
