import { create } from "zustand";
import { NewEventInterface, Post } from "../interfaces";
import { api } from "../utils/api";

interface PostState {
  posts: Post[] | null;
  getPost: (providerId: string, postId: string) => Promise<Post>;
  addCommentToPost: (comment: NewEventInterface) => Promise<void>;
  deleteComment: (
    commentId: string,
    providerId: string,
    postId: string
  ) => Promise<void>;
}

const usePostStore = create<PostState>((set, get) => {
  return {
    posts: [],
    getPost: async (providerId: string, postId: string) => {
      let post = get().posts?.find((p) => p.id === postId);
      if (post) {
        return post;
      }
      const response = await api.post<Post>("/meta/posts", {
        providerId,
        postId,
      });
      post = response.data;
      get().posts
        ? set({ posts: [...(get().posts ?? []), post] })
        : set({ posts: [post] });
      return post;
    },
    addCommentToPost: async (comment: NewEventInterface): Promise<void> => {
      const post = get().posts?.find(
        (p) => p.id === comment.event.comment?.relatedPostId
      );
      if (post) {
        if (post.events) {
          let existComment = post.events.find(
            (e) => e.providerId === comment.event.providerId
          );
          !existComment && post.events.push(comment.event);
        } else {
          post.events = [comment.event];
        }
      } else {
        await get().getPost(
          comment.instance.providerId,
          comment.event.comment!.relatedPostId
        );
        get().addCommentToPost(comment);
      }
    },
    deleteComment: async (
      commentId: string,
      providerId: string,
      postId: string
    ): Promise<void> => {
      const response = await api.delete(
        `/meta/posts/comment/${providerId}/${commentId}`
      );
      if (response.status === 200) {
        const post = get().posts?.find((p) => p.id === postId);
        if (post?.events) {
          post.events = post.events.filter((e) => e.providerId !== commentId);
          set({
            posts: get().posts?.map((p) => (p.id === post.id ? post : p)),
          });
        }
      }
    },
  };
});

export default usePostStore;
