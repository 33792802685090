import { ChatInterface, EventType } from "../interfaces/chat";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Input,
  ScrollShadow,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { useChatContext } from "../context";
import EventComponent from "./chat/eventComponent";
import EmojiPicker from "emoji-picker-react";

const ChatComponent = () => {
  const { selectedChat, chats, replyMessage } = useChatContext();
  const [isEmojiOpen, setIsEmojiOpen] = useState<boolean>(false);
  const [textInputValue, setTextInputValue] = useState<string>("");
  const [chat, setChat] = useState<ChatInterface>();
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const handleSendMessage = () => {
    if (textInputValue !== "") {
      replyMessage({
        sender: selectedChat!.consumer,
        instance: selectedChat!.instance,
        event: {
          type: EventType.Message,
          message: textInputValue,
          timestamp: new Date().getTime(),
          providerId: "",
          isDeleted: false,
        },
      });
      setTextInputValue("");
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };
  useEffect(() => {
    if (bottomRef?.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  });
  useEffect(() => {
    setChat(
      chats.find(
        (x) => x.consumer.providerId === selectedChat?.consumer.providerId
      )
    );
  }, [chats, selectedChat?.consumer.providerId]);
  const renderEvents = () => {
    if (chat) {
      return chat?.events.map((event) => {
        return (
          <EventComponent
            key={event.providerId}
            sender={chat.consumer}
            event={event}
            instance={chat.instance}
          />
        );
      });
    } else {
      return (
        <div className="h-full my-auto w-full flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="size-16"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z"
            />
          </svg>

          <p className="text-lg font-semibold">
            Este chat aun no tiene mensajes
          </p>
        </div>
      );
    }
  };
  return (
    <div className="h-screen my-auto mx-0 w-full p-0 shadow-transparent shadow-xl flex flex-col p-5 ">
      <Card className="h-screen my-auto bg-gray-100 mx-0 w-full p-0 shadow-transparent shadow-xl flex flex-col">
        <CardHeader className="w-full p-0">
          {chat && (
            <div className="w-full bg-white text-black flex row h-24 items-center pl-5 shadow-lg">
              <Avatar
                size={"lg"}
                src={
                  chat.consumer.profilePicture ??
                  "https://i.pravatar.cc/150?u=a042581f4e29026024d"
                }
              />
              <p className="ml-5 text-black">
                {selectedChat?.consumer.name === "-"
                  ? selectedChat.consumer.providerId
                  : selectedChat?.consumer.name}
              </p>
            </div>
          )}
        </CardHeader>

        <ScrollShadow className="h-dvh m-5">
          {chat ? (
            renderEvents()
          ) : (
            <div className="h-full my-auto w-full flex flex-col items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-16"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
              <p className="text-lg font-semibold">
                Selecciona un chat para comenzar a interactuar.
              </p>
            </div>
          )}
        </ScrollShadow>
        <div
          className="flex flex-row bg-white rounded-xl mx-5 mb-5"
          ref={bottomRef}
        >
          <EmojiPicker
            open={isEmojiOpen}
            width={"100%"}
            skinTonesDisabled={true}
            searchPlaceholder="Buscar"
            onEmojiClick={(emoji) =>
              setTextInputValue((prev) => prev + emoji.emoji)
            }
          />
        </div>
        {chat?.events.some((p) => p.type === "message") && (
          <div
            className="flex flex-row bg-white rounded-xl mx-5 mb-5"
            ref={bottomRef}
          >
            <Input
              label="Responder mensaje privado"
              size="md"
              variant="bordered"
              value={textInputValue}
              onKeyDown={(e) => handleKeyPress(e)}
              onChange={(e) => setTextInputValue(e.target.value)}
              autoFocus
              endContent={
                <section className="flex flex-column my-auto">
                  <Button
                    isIconOnly
                    aria-label="Emoji"
                    onClick={() => {
                      replyMessage({
                        sender: chat.consumer,
                        instance: chat.instance,
                        event: {
                          type: EventType.Message,
                          message: textInputValue,
                          timestamp: new Date().getTime(),
                          providerId: "",
                          isDeleted: false,
                        },
                      });
                      setTextInputValue("");
                    }}
                    variant="light"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                      />
                    </svg>
                  </Button>
                  <Button
                    isIconOnly
                    aria-label="Emoji"
                    onClick={() => {}}
                    variant="light"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"
                      />
                    </svg>
                  </Button>
                  <Button
                    isIconOnly
                    aria-label="Emoji"
                    onClick={() => setIsEmojiOpen(!isEmojiOpen)}
                    variant="light"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.182 15.182a4.5 4.5 0 0 1-6.364 0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75Zm-.375 0h.008v.015h-.008V9.75Z"
                      />
                    </svg>
                  </Button>
                </section>
              }
            />
          </div>
        )}
      </Card>
    </div>
  );
};
export default ChatComponent;
