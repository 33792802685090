import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
} from "@nextui-org/react";
import {
  direction,
  EventType,
  Instance,
  Sender,
  type Event as EventInterface,
} from "../../interfaces";
import formatDate from "../../utils/formatedDate";
interface MessageComponentInterface {
  sender: Sender;
  event: EventInterface;
  instance: Instance;
}
const MessageComponent = ({
  sender,
  event,
  instance,
}: MessageComponentInterface) => {
  const getName = () => {
    let name;
    if (event.direction === direction.Incoming) {
      name = sender.name !== "-" ? sender.name : sender.providerId;
    } else {
      name = instance.name;
    }
    return name;
  };
  return (
    <div
      className={`flex flex-row items-start space-x-3 my-5 ${
        event.direction === direction.Incoming ? "justify-start" : "justify-end"
      }`}
      key={event.providerId}
    >
      {event.direction === direction.Incoming && (
        <Avatar
          src={
            sender.profilePicture ??
            "https://i.pravatar.cc/150?u=a042581f4e29026024d"
          }
        />
      )}
      <div className="flex flex-col space-y-1">
        <Card
          className="ml px-2 py-1"
          style={{ minWidth: "15rem", maxWidth: "35rem" }}
        >
          <CardHeader className="py-2">
            <p className="text-secondary-500">{getName()}</p>
          </CardHeader>
          <CardBody>
            {event.message && (
              <p className="text-gray-900 font-light">{event.message}</p>
            )}
            {event.attachments?.map((attachment) => (
              <Image
                className="mt-3"
                key={event.providerId}
                src={attachment.url}
                alt={event.providerId}
              />
            ))}
          </CardBody>
          <CardFooter
            className={`flex-col ${
              event.type === EventType.Comment ? "py-0" : ""
            }`}
          >
            <div className="text-xs font-light w-full text-left text-cyan-900">
              {formatDate(event.timestamp)}
            </div>
          </CardFooter>
        </Card>
      </div>
      {event.direction === direction.Outgoing && (
        <Avatar
          src={
            instance.profilePicture ??
            "https://i.pravatar.cc/150?u=a04258114e29026302d"
          }
        />
      )}
    </div>
  );
};
export default MessageComponent;
