"use client";

import { Avatar, Badge, Card, CardBody, Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";

import { Sender } from "../../interfaces";
import { useChatContext } from "../../context";

type ChatUserModel = {
  user: Sender | null;
  lastMessage?: string;
};
const newMessageAnimation =
  "animate-pulse font-bold pt-1 transition ease-in-out";
const messageClass = "font-normal pt-1 transition ease-in-out";

const ChatUserComponent: React.FC<ChatUserModel> = ({
  user,
  lastMessage,
}: ChatUserModel) => {
  const { selectedChat, setSelectedChat, chats } = useChatContext();
  return (
    <Card
      isBlurred
      isPressable
      shadow="sm"
      key={user!.providerId}
      className={"w-full border-none rounded-small mb-3"}
      style={{
        backgroundColor:
          user?.providerId === selectedChat?.consumer.providerId
            ? "black"
            : "white",
      }}
      onPress={(e) => {
        const chat = chats.find(
          (c) => c.consumer.providerId === user?.providerId
        );
        setSelectedChat(chat);
      }}
    >
      <CardBody>
        <div className="flex gap-3 items-stretch bg-red">
          <Avatar
            alt={user?.name ?? user?.providerId}
            className="flex-shrink-0"
            size="lg"
            src={
              user?.profilePicture ??
              "https://i.pravatar.cc/150?u=a042581f4e29026024d"
            }
          />
          <div className="flex flex-col items-start">
            <div className="flex items-start justify-content-center">
              <span
                className={
                  "text-base " +
                  (user?.providerId === selectedChat?.consumer.providerId
                    ? "text-white"
                    : "text-black")
                }
              >
                {user?.name && user.name !== "-"
                  ? user.name.length > 30
                    ? user.name.slice(0, 30) + "..."
                    : user.name
                  : user?.providerId}
              </span>
            </div>
            <div className="flex justify-between content-between mt-2 w-full">
              <Tooltip content="Mensajes">
                {user?.providerId === selectedChat?.consumer.providerId ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                    color="white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                    />
                  </svg>
                ) : (
                  <Badge content=" " size="sm" color="success">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                      color="black"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                      />
                    </svg>
                  </Badge>
                )}
              </Tooltip>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
export default ChatUserComponent;
