import axios, { AxiosResponse } from "axios";
import { api } from "../utils/api";
import {
  AddInstancePayload,
  AddInstanceResponse,
  MetaAccountsResponse,
  Accounts,
} from "../interfaces";
import { createContext, useContext, useState, ReactNode } from "react";
import { useAuthContext } from "./auth";

export interface InstanceContextType {
  instances: AddInstanceResponse[];
  addInstance: (
    payload: AddInstancePayload
  ) => Promise<AddInstanceResponse | null>;
  updateInstance: (
    payload: AddInstancePayload,
    id: string
  ) => Promise<AddInstanceResponse | null>;
  getUserInstances: (ownerId: string) => Promise<AddInstanceResponse[] | null>;
  getMetaInstances: () => void;
  validateInstance: (metaId: string) => Promise<AddInstanceResponse | null>;
}

const InstanceContext = createContext<InstanceContextType | undefined>(
  undefined
);

export const InstanceProvider = ({ children }: { children: ReactNode }) => {
  const [instances, setInstances] = useState<AddInstanceResponse[]>([]);
  const { user, hasInstagram } = useAuthContext();

  const addInstance = async (
    payload: AddInstancePayload
  ): Promise<AddInstanceResponse | null> => {
    try {
      const response: AxiosResponse<AddInstanceResponse> = await api.post(
        "instances",
        payload
      );
      if (response.status === 200) {
        getUserInstances(payload.owner);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const updateInstance = async (
    payload: AddInstancePayload,
    id: string
  ): Promise<AddInstanceResponse | null> => {
    try {
      console.log("updateInstance", payload);
      const response: AxiosResponse<AddInstanceResponse> = await api.patch(
        `Instances/${id}`,
        payload
      );
      if (response.status === 200) {
        getUserInstances(payload.owner);
      }
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getUserInstances = async (
    ownerId: string
  ): Promise<AddInstanceResponse[] | null> => {
    try {
      const response: AxiosResponse<AddInstanceResponse[]> = await api.get(
        `instances/owner/${ownerId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setInstances([...response.data]);
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  const validateInstance = async (
    metaId: string
  ): Promise<AddInstanceResponse | null> => {
    try {
      const response: AxiosResponse<AddInstanceResponse> = await api.get(
        `instances/validate-with-meta-id/${metaId}`
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  };
  const getMetaInstances = async () => {
    try {
      const fields = `access_token,name,picture,username${
        hasInstagram ? ",instagram_business_account" : ""
      }`;
      // Verificar que el token y userId existen
      if (!user?.token || !user?.providerId) {
        console.error("No access token or user ID available");
        return;
      }

      const response: AxiosResponse<MetaAccountsResponse> = await axios.get(
        `https://graph.facebook.com/${
          process.env.REACT_APP_META_API_VERSION ?? "v18.0"
        }/${user.providerId}/accounts`,
        {
          params: {
            fields,
            access_token: user.token,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const accounts: Accounts[] = response.data.data;
      const updatePromises = accounts.map(async (account: Accounts) => {
        const validFacebookInstance = await validateInstance(account.id);
        const longTermToken = await exchangeTokenForLongLivedToken(
          account.access_token
        );
        let payload: AddInstancePayload = {
          name: account.name,
          meta: {
            token: longTermToken,
            providerId: account.id,
            type: "facebook",
            profilePicture: account.picture.data.url,
            subscriptions: "all",
          },
          owner: user?.id ?? "-",
        };

        if (
          validFacebookInstance !== null &&
          validFacebookInstance !== undefined
        ) {
          await updateInstance(payload, validFacebookInstance._id);
        } else {
          await addInstance(payload);
        }

        if (hasInstagram) {
          const instagramAccount = account.instagram_business_account;
          if (instagramAccount) {
            let instagramProfilePic;
            try {
              const igResponse = await axios.get(
                `https://graph.facebook.com/${
                  process.env.REACT_APP_META_API_VERSION ?? "v18.0"
                }/${instagramAccount.id}`,
                {
                  params: {
                    fields: "profile_picture_url",
                    access_token: account.access_token,
                  },
                }
              );
              instagramProfilePic = igResponse.data.profile_picture_url;
            } catch (error) {
              console.error("Error fetching Instagram profile picture:", error);
            }

            let payload: AddInstancePayload = {
              name: instagramAccount.username ?? account.name,
              meta: {
                token: longTermToken,
                providerId: instagramAccount.id,
                type: "instagram",
                profilePicture: instagramProfilePic,
                subscriptions: "all",
              },
              owner: user?.id ?? "-",
            };
            const validInstagramInstance = await validateInstance(
              instagramAccount.id
            );
            if (
              validInstagramInstance !== null &&
              validInstagramInstance !== undefined
            ) {
              await updateInstance(payload, validInstagramInstance._id);
            } else {
              await addInstance(payload);
            }
          }
        }
        return account;
      });

      await Promise.all(updatePromises);
    } catch (error) {
      setInstances([]);
      console.error("No se pudo obtener la lista de instancias", error);
    }
  };
  const exchangeTokenForLongLivedToken = async (
    shortLivedToken: string
  ): Promise<string> => {
    const clientId =
      process.env.REACT_APP_META_APP_ID?.toString() ?? "514045830281007"; // Reemplaza con tu app ID
    const clientSecret =
      process.env.REACT_APP_META_APP_SECRET?.toString() ??
      "e766743e12bf3b30ccb29fae24599a3f"; // Reemplaza con tu app secret

    const url = `https://graph.facebook.com/${
      process.env.REACT_APP_META_API_VERSION?.toString() ?? "v18.0"
    }/oauth/access_token`;

    try {
      const response = await axios.get(url, {
        params: {
          grant_type: "fb_exchange_token",
          client_id: clientId,
          client_secret: clientSecret,
          fb_exchange_token: shortLivedToken,
        },
      });

      const { access_token } = response.data;
      return access_token; // Retorna el token de larga duración
    } catch (error: any) {
      console.error("Error intercambiando el token:", error.message);
      throw error;
    }
  };
  const contextValue: InstanceContextType = {
    instances,
    addInstance,
    updateInstance,
    getUserInstances,
    getMetaInstances,
    validateInstance,
  };

  return (
    <InstanceContext.Provider value={contextValue}>
      {children}
    </InstanceContext.Provider>
  );
};

export const useInstanceContext = (): InstanceContextType => {
  const context = useContext(InstanceContext);

  if (!context) {
    throw new Error(
      "useInstanceContext debe usarse dentro de un InstanceProvider"
    );
  }

  return context;
};
