import { format } from "date-fns";

const formatDate = (timestamp: number) => {
  timestamp = timestamp < 1e12 ? timestamp * 1000 : timestamp;
  const date = new Date(timestamp);
  const now = new Date();
  const isToday = date.toDateString() === now.toDateString();
  return isToday
    ? `Hoy ${date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}`
    : format(date, "dd-MM-yyyy HH:mm");
};
export default formatDate;
