"use client";

import React, { useEffect, useState } from "react";
import LeftPanel from "../components/LeftPanel";
import { useNavigate } from "react-router-dom";
import { useAuthContext, useChatContext } from "../context";
import ChatComponent from "../components/chatComponent";
import { Avatar, Button } from "@nextui-org/react";

const ChatPage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { chats } = useChatContext();
  const [loading, setLoading] = useState<boolean>(chats.length === 0);
  useEffect(() => {
    if (user === undefined) {
      navigate("/");
    }
    setLoading(false);
  }, [navigate, user]);
  useEffect(() => {
    setLoading(chats.length === 0);
  }, [chats]);
  return loading ? (
    <main className={"h-screen bg-gray-100"}>
      <div
        className={"flex flex-col h-screen place-content-center items-center"}
      >
        <div>
          <span
            style={{ animationDuration: "2s" }}
            className="absolute inline-flex h-full w-full animate-ping duration-200 rounded-full bg-secondary-400 opacity-75"
          ></span>
          <Avatar
            isBordered
            className="w-48 h-48 text-large"
            color="success"
            src="https://i.pravatar.cc/150?u=a04258114e29026302d"
          />
        </div>
        <p className={"text-3xl mt-8 text-secondary-600"}>
          Hola {user?.name ?? "Agente CYT"}, aun no tienes conversaciones
          activas.
        </p>
        <Button
          onClick={() => navigate("/welcome")}
          className="mt-10"
          color="secondary"
          startContent={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="size-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"
              />
            </svg>
          }
        >
          Volver
        </Button>
      </div>
    </main>
  ) : (
    <main className={"h-screen bg-gray-300"}>
      <div className={"flex flex-row h-screen place-content-center"}>
        <LeftPanel />
        <ChatComponent />
      </div>
    </main>
  );
};
export default ChatPage;
