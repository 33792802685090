export interface Instance {
  _id: string;
  providerId: string;
  type: ChatType;
  owner: string;
  name: string;
  profilePicture?: string;
}

export interface Sender {
  providerId: string;
  name: string;
  profilePicture?: string;
}

export interface Comment {
  parentId: string;
  relatedUrl: string;
  relatedPostId: string;
}

export interface Event {
  type: EventType;
  message?: string;
  direction?: direction;
  isDeleted: boolean;
  timestamp: number;
  providerId: string;
  comment?: Comment;
  attachments?: Attachment[];
}
export interface Post {
  message: string;
  createdTime: Date;
  id: string;
  fullPicture: string;
  events?: Event[];
}
export interface NewEventInterface {
  instance: Instance;
  sender: Sender;
  event: Event;
}
export enum direction {
  Incoming = "incoming",
  Outgoing = "outgoing",
}
export interface ChatInterface {
  consumer: Sender;
  instance: Instance;
  events: Event[];
}

export interface Attachment {
  type: AttachmentType;
  url: string;
}

export enum ChatType {
  Facebook = "facebook",
  Instagram = "instagram",
}

export enum EventType {
  Message = "message",
  Comment = "comment",
  Reaction = "reaction",
  Attachment = "attachment",
}

export enum AttachmentType {
  Image = "image",
  Video = "video",
  Audio = "audio",
  File = "file",
}
