import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Input,
  Tooltip,
} from "@nextui-org/react";
import {
  EventType,
  Instance,
  type Event as EventInterface,
  Sender,
  direction,
} from "../../interfaces";
import TrashIcon from "../../assets/icons/trashIcon";
import SendIcon from "../../assets/icons/sendIcon";
import MessageIcon from "../../assets/icons/messageIcon";
import { useState } from "react";
import { useChatContext } from "../../context";
import formatDate from "../../utils/formatedDate";

interface CommentComponentInterface {
  isPrimary?: boolean;
  sender: Sender;
  instance: Instance;
  event: EventInterface;
  onDelete: () => void;
}
interface InputActionsProps {
  sendMessage: () => void;
  sendComment: () => void;
}

const InputActions = ({ sendMessage, sendComment }: InputActionsProps) => {
  return (
    <ButtonGroup>
      <Tooltip content="Enviar mensaje privado">
        <Button
          size="sm"
          variant="light"
          className="m-0 p-0"
          onClick={sendMessage}
        >
          <MessageIcon />
        </Button>
      </Tooltip>
      <Tooltip content="Responder comentario">
        <Button size="sm" variant="light" onClick={sendComment}>
          <SendIcon />
        </Button>
      </Tooltip>
    </ButtonGroup>
  );
};
const CommentComponent = ({
  isPrimary = false,
  sender,
  instance,
  event,
  onDelete,
}: CommentComponentInterface) => {
  const [replyMessageText, setReplyMessageText] = useState<string>("");
  const { replyMessage } = useChatContext();
  const styles = {
    card: `my-2 w-full ${isPrimary ? "bg-white" : "bg-gray-200"}`,
    cardHeader: " text-white " + (isPrimary ? "bg-cyan-700" : "bg-gray-700"),
    cardBody: `flex flex-col justify-between `,
    cardFooter: "flex flex-col",
    title: "text-md font-regular",
    button: "focus:outline-none text-danger flex items-center",
    createdAt: `text-left block mt-2 font-thin text-xs ${
      isPrimary ? "text-cyan-700" : "text-gray-600"
    }`,
  };
  const handleSendMessage = (type: EventType) => {
    if (replyMessageText !== "") {
      replyMessage({
        sender: sender,
        instance: instance,
        event: {
          type: type,
          message: replyMessageText,
          timestamp: new Date().getTime(),
          providerId: "",
          isDeleted: false,
          comment: {
            parentId: event.providerId,
            relatedUrl: event.comment!.relatedUrl,
            relatedPostId: event.comment!.relatedPostId,
          },
        },
      });
      setReplyMessageText("");
    }
  };
  return (
    <Card className={styles.card}>
      <CardHeader className={styles.cardHeader}>
        {event.direction === direction.Outgoing
          ? instance.name
          : sender.name ?? "-"}
      </CardHeader>
      <CardBody className={styles.cardBody}>
        <section className="w-full mb-3">
          <p className={styles.title}>{event.message}</p>
          {event.attachments?.map((attachment) => (
            <img
              key={attachment.url}
              src={attachment.url}
              alt={attachment.url}
              className="w-1/4"
            />
          ))}
        </section>

        <section className="w-full flex justify-between mt-2">
          <p className={styles.createdAt}>{formatDate(event.timestamp)}</p>
          <button onClick={onDelete} tabIndex={0} className={styles.button}>
            <TrashIcon size="20" />
          </button>
        </section>
        {isPrimary && <Divider className="mt-3" />}
      </CardBody>
      <CardFooter className={styles.cardFooter}>
        {isPrimary && (
          <Input
            value={replyMessageText}
            onChange={(e) => setReplyMessageText(e.target.value)}
            placeholder="Responde al comentario"
            endContent={
              <InputActions
                sendComment={() => handleSendMessage(EventType.Comment)}
                sendMessage={() => handleSendMessage(EventType.Message)}
              />
            }
          />
        )}
      </CardFooter>
    </Card>
  );
};
export default CommentComponent;
