import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { ChatInterface, NewEventInterface } from "../interfaces";
import { io } from "socket.io-client";
import { api } from "../utils/api";
import { useInstanceContext } from "./instance";

const apiUrl = process.env.REACT_APP_API_URL;

const getMetaProfile = async (providerId: string, profileId: string) => {
  const response = await api.get(
    `${apiUrl}meta/profile/${providerId}/${profileId}`
  );
  return {
    profilePicture: response.data.profile_pic,
    name: response.data.name,
  };
};

export interface ChatContextInterface {
  chats: ChatInterface[];
  selectedChat: ChatInterface | null;
  setSelectedChat: any;
  replyMessage: (message: NewEventInterface) => void;
  deleteEvent: (eventId: string) => void;
}
export const ChatContext = createContext<ChatContextInterface | undefined>(
  undefined
);
export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [chats, setChats] = useState<ChatInterface[]>([]);
  const [selectedChat, setSelectedChat] = useState<ChatInterface | null>(null);
  const { instances } = useInstanceContext();
  useEffect(() => {
    const socket = io(apiUrl + "realtime-chat");
    socket.on("connect", () => {
      console.log("Connected to socket.io server");
    });

    socket.on("incomingEvent", async (event: NewEventInterface) => {
      if (event.sender.providerId !== event.instance.providerId) {
        event.event.isDeleted = event.event.isDeleted ?? false;

        // Verifica si el chat ya existe en el estado actual
        const existingChat = chats.find(
          (c) => c.consumer.providerId === event.sender.providerId
        );

        if (existingChat) {
          // Verifica si el evento ya existe en el chat
          const messageExists = existingChat.events.some(
            (e) => e.providerId === event.event.providerId
          );

          if (!messageExists) {
            // Si el evento no existe, actualiza el estado
            setChats((prevChats) => {
              return prevChats.map((chat) =>
                chat.consumer.providerId === event.sender.providerId
                  ? { ...chat, events: [...chat.events, event.event] }
                  : chat
              );
            });
          }

          // No hay más acciones necesarias si el chat ya existe
          return;
        }

        // Si el chat no existe, obtenemos datos asíncronos antes de actualizar
        const metaProfile = await getMetaProfile(
          event.instance.providerId,
          event.sender.providerId
        );

        event.sender.profilePicture = metaProfile.profilePicture;
        event.sender.name = metaProfile.name;

        const instance = instances.find(
          (i) => i.meta?.providerId === event.instance.providerId
        );

        event.instance.profilePicture =
          instance?.meta?.profilePicture ?? undefined;

        // Agrega el nuevo chat al estado
        setChats((prevChats) => [
          ...prevChats,
          {
            consumer: event.sender,
            instance: event.instance,
            events: [event.event],
          },
        ]);
      }
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from socket.io server");
    });

    return () => {
      socket.disconnect();
    };
  }, [chats, instances]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const replyMessage = (message: NewEventInterface) => {
    const socket = io(apiUrl + "realtime-chat");
    socket.emit("outgoingEvent", message);
  };
  const deleteEvent = (eventId: string) => {
    setChats((prevChats) =>
      prevChats.map((chat) => ({
        ...chat,
        events: chat.events.filter((ev) => ev.providerId !== eventId),
      }))
    );
  };

  const contextValue = {
    chats,
    selectedChat,
    setSelectedChat,
    replyMessage,
    deleteEvent,
  };

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};
export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error("useChatContext must be used within a ChatProvider");
  }
  return context;
};
